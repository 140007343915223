import React from "react";
import "./ContactForm.css";
import { useState } from "react";

const ContactForm = (props) => {
  const [datosJson, setDatosJson] = useState(false);

  const modificarValor = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
    console.log(e);
  };

  const [formulario, setFormulario] = useState({
    nombre: "",
    email: "",
    mensaje: "",
  });

  const enviar = async (nombre, email, mensaje) => {
    const enviarFormulario = {
      nombre: nombre,
      email: email,
      mensaje: mensaje,
    };

    let formBody = [];
    for (let property in enviarFormulario) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(enviarFormulario[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log(formBody);
    const data = await fetch(
      process.env.REACT_APP_API_PRD + "/public/api/enviar_correo",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      }
    );

    const json_data = await data.json();
    console.log(data);
    //window.alert(json_data.mesasge);
    setDatosJson(json_data);
    console.log(json_data);
  };

  const enviado = (e) => {
    e.preventDefault();
    enviar(formulario.nombre, formulario.email, formulario.mensaje);
  };

  return (
    <>
      <form className={"contact-form"} onSubmit={enviado}>
        <div className={"input-group"}>
          <input
            value={formulario.nombre}
            className="nombre"
            type="text"
            name={"nombre"}
            placeholder={"Nombre y apellidos"}
            required={true}
            onChange={(e) => modificarValor(e)}
          />

          <input
            value={formulario.email}
            className="email"
            type="email"
            name={"email"}
            placeholder={"Email"}
            required={true}
            onChange={(e) => modificarValor(e)}
          />
        </div>

        <div className={"input-group"}>
          <textarea
            value={formulario.mensaje}
            name="mensaje"
            id=""
            cols="30"
            rows="10"
            placeholder={"Mensaje"}
            onChange={(e) => modificarValor(e)}
          />
        </div>

        <button type="submit" className="btn-primary button-nosotros-form">
          Enviar
        </button>

        <div
          className={datosJson !== false ? `test ${datosJson.status}` : "test "}
        >
          {datosJson !== false && datosJson.msg}
        </div>
      </form>
    </>
  );
};

export default ContactForm;

import React from "react";

import "./SectionTitle.css";
import banner from "../../images/Franja-Internas.jpg";

const SectionTitle = (props) => {
  return (
    <>
      <section
        className={"section-title"}
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container">
          <h1>{props.title ?? "Cursos de Marketing y Ventas"}</h1>
        </div>
      </section>
    </>
  );
};

export default SectionTitle;

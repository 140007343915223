import React from "react";

import "./Banner.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import chicaNotebook from "../../images/Banner-Principal.jpg";

const Banner = () => {
  return (
    <>
      <div className="carousel-item">
        <div className="banner-principal-container">
          <img src={chicaNotebook} alt="Imagen de chica" />
        </div>
      </div>
    </>
  );
};

export default Banner;
